<template>
  <nav class="navbar navbar-expand-lg justify-content-end px-3 pb-3 sticky-top bg-white">
    <router-link class="btn btn-secondary me-3" to="/kitchen" v-if="!$route.path.startsWith('/kitchen') && isLoggedIn">Kitchen-Screen öffnen</router-link>
    <router-link class="navbar-brand" to="/">
      <img src="@/assets/logo_transparent.png" class="logo" />
    </router-link>
    <ProfileIcon v-if="isLoggedIn"></ProfileIcon>
  </nav>
</template>

<script>
import { useAuthStore } from "../../stores/auth";
import { RouterLink, useRoute } from "vue-router";
import { computed } from "vue";

export default {
  setup() {
    const auth = useAuthStore();
    const isLoggedIn = computed(() => auth.isAuthenticated);

    return {
      auth,
      isLoggedIn,
    };
  },
};
</script>

<style lang="scss">
.navbar {
  .logo {
    margin-left: 16px;
    max-width: 150px;
  }
}
</style>
