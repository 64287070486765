<template>
  <img src="@/assets/header_divider.png" class="header-divider" />
</template>

<style lang="scss">
.header-divider {
  position: absolute;
  bottom: -54px;
  right: 4px;
}
</style>
