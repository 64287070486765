import { defineStore } from "pinia";
import { loadSettings } from "../services/settings-service";

const AUTO_REFRESH_INTERVAL_MS = 1000 * 5;

export const useSettingsStore = defineStore("settings", {
  state: () => {
    return {
      deliverySettings: {
        deliveryDays: "3,4,5,6",
        deliveryOrderStart: "10:00",
        deliveryOrderEnd: "11:00",
        pickupTime: "00:10:00",
      },
      openingSettings: {
        isOpen: true,
      },
    };
  },
  getters: {
    settings: (state) => state.deliverySettings,
    isInDeliveryHour: (state) => {
      const now = new Date();
      const start = new Date();
      const end = new Date();
      const deliveryDays = state.deliverySettings.deliveryDays.split(",").map((day) => parseInt(day));

      start.setHours(parseInt(state.deliverySettings.deliveryOrderStart.split(":")[0]), parseInt(state.deliverySettings.deliveryOrderStart.split(":")[1]));
      end.setHours(parseInt(state.deliverySettings.deliveryOrderEnd.split(":")[0]), parseInt(state.deliverySettings.deliveryOrderEnd.split(":")[1]));

      return now >= start && now <= end && deliveryDays.includes(now.getDay());
    },
    isOpen: (state) => state.openingSettings.isOpen,
    deliveryDayStart: (state) => {
      const startDeliveryDay = state.deliverySettings.deliveryDays.split(",").map((day) => parseInt(day))[0];
      return startDeliveryDay;
    },
    deliveryDayEnd: (state) => {
      const endDeliveryDay = state.deliverySettings.deliveryDays.split(",").map((day) => parseInt(day))[
        state.deliverySettings.deliveryDays.split(",").map((day) => parseInt(day)).length - 1
      ];
      return endDeliveryDay;
    },
  },
  actions: {
    async loadSettings() {
      try {
        const result = await loadSettings();
        const settings = JSON.parse(result);

        // Apply all given properties to the store
        Object.keys(settings).forEach((settingsKey) => {
          this[settingsKey] = { ...this[settingsKey], ...settings[settingsKey] };
        });
      } catch (error) {
        console.error(error);
      }
    },
    initializeAutoRefresh() {
      setInterval(() => {
        this.loadSettings();
      }, AUTO_REFRESH_INTERVAL_MS);
    },
  },
});
