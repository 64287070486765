<template>
    <div class="header-user-profile">
        <b-button class="user-profile-button" v-if="isAuthenticated" variant="danger" @click="logout">Logout</b-button>
    </div>
</template>

<script>
import { storeToRefs } from 'pinia';
import { useAuthStore } from '@/stores/auth';
import router from '../../router';

export default {
    setup() {
        const auth = useAuthStore()

        const { isAuthenticated } = storeToRefs(auth)

        return {
            auth,
            isAuthenticated
        }
    },
    methods: {
        logout() {
            this.auth.logout()
            router.push('/')
        }
    }
}
</script>

<style lang="scss">
.header-user-profile {
}
</style>