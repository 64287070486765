<template>
  <div class="main-view container">
    <Header v-if="!$route.meta.hideHeader" />
    <template v-if="!inventoryStore.isInventoryAvailable">
      <Loader type="border" variant="primary" label="Loading..." />
    </template>
    <template v-else>
      <RouterView />
    </template>
  </div>
</template>
<script>
import { RouterView } from "vue-router";
import Header from "./components/layout/Header.vue";
import { useOrderStore } from "./stores/order";
import { useInventoryStore } from "./stores/inventory";

export default {
  setup() {
    const inventoryStore = useInventoryStore();
    const orderStore = useOrderStore();

    return {
      inventoryStore,
      orderStore,
    };
  },
};
</script>
<style lang="scss">
@import "@/scss/variables";

.main-view {
  margin-bottom: 40px;

  .debug-view {
    display: none;

    @include media-breakpoint-up(md) {
      display: block;

      position: fixed;
      top: 0;
      right: 0;
      width: 750px;
      height: calc(100% - 60px);
      background-color: #eee;
      padding: 10px;
      margin: 30px 30px 30px 0;
      overflow: scroll;
    }
  }
}
</style>
