export class PriceCalculatorService {
  constructor() {}

  calculatePrice(dishOrMenu) {
    return (this.sumPrices(dishOrMenu) / 100).toFixed(2);
  }

  sumPrices(data) {
    let totalPrice = 0;
    if (typeof data === "object" && data !== null) {
      for (let key in data) {
        if (key === "price") {
          totalPrice += data[key];
        } else {
          totalPrice += this.sumPrices(data[key]);
        }
      }
    }

    return totalPrice;
  }
}
