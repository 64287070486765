<template>
  <div class="bowl-ingredients">
    <h1>{{ $t("ConfigureDishIngredients_Bowl_Title") }}</h1>
    <div class="row mb-2 mb-md-3">
      <div class="col-12 col-md-6">
        <div class="current-base">
          <h4>Deine Basis: {{ $forCurrentLang(currentBowl.base.displayName) }}</h4>
          <AnimateContent :delay="0" class="mb-3">
            <img :src="currentBowl.base.uri" alt="" class="d-none d-md-block" />
          </AnimateContent>
          <p class="fs-5">
            {{
              bowlSize.ingredients == 1
                ? $t("ConfigureDish_Bowl_Size_IngredientsSingular", { ingredients: bowlSize.ingredients })
                : $t("ConfigureDish_Bowl_Size_IngredientsPlural", { ingredients: bowlSize.ingredients })
            }}
          </p>
        </div>
      </div>
      <div class="col-12 col-md-6" v-if="premixed.length > 0">
        <h4>{{ $t("ConfigureDish_Bowl_PremixedTitle") }}</h4>
        <div
          v-for="premixedBowl in [...premixed, customBowl]"
          :key="premixedBowl.name"
          class="col item small left-aligned"
          :class="{ active: currentBowl.name === premixedBowl.name }"
          @click="selectBowl(premixedBowl)"
        >
          <div class="row">
            <div class="col-3">
              <img :src="premixedBowl.uri" alt="" />
            </div>
            <div class="col-9">
              <h5>{{ $forCurrentLang(premixedBowl.displayName) }}</h5>
              <p class="m-0">
                <b-badge variant="light" v-for="ingredient in premixedBowl.ingredients" class="me-1">{{ $forCurrentLang(ingredient.displayName) }}</b-badge>
                <b-badge variant="light" v-for="ingredient in premixedBowl.toppings" class="me-1">{{ $forCurrentLang(ingredient.displayName) }}</b-badge>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row mb-2 mb-md-3 ingredients">
      <div class="col-12 mb-4 border-bottom">
        <div class="row">
          <h4>{{ $t("ConfigureDishIngredients_Mixins_Title") }}</h4>
          <div class="d-flex flex-col mb-3">
            <div class="flex-shrink-1 py-3">
              <b-button
                variant="primary"
                class="fs-1 px-4 lh-base"
                @click="
                  openDetailModal(
                    $t('ConfigureDishIngredients_Mixins_ModalTitle'),
                    ingredientsCategories,
                    inventory.bowls.ingredients,
                    currentBowl.ingredients,
                    (item) => item?.category,
                    isActiveIngredient,
                    selectIngredient
                  )
                "
              >
                <i-mdi-plus-thick class="large-inline" v-if="(currentDish.bowl.ingredients || []).length === 0" />
                <i-mdi-pencil class="large-inline" v-else />
              </b-button>
            </div>
            <div>
              <div class="p-3 d-flex flex-wrap fs-5">
                <b-badge variant="light" v-for="ingredient in currentMixIns()" class="ingredient-badge">
                  <img v-if="ingredient.uri" :src="ingredient.uri" alt="" />
                  {{ $forCurrentLang(ingredient.displayName) }}
                  <span class="d-inline-block" v-if="ingredient.price > 0">+ {{ (ingredient.price / 100).toFixed(2) }} CHF</span>
                </b-badge>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 mb-4 border-bottom">
        <div class="row">
          <h4>{{ $t("ConfigureDishIngredients_Toppings_Title") }}</h4>
          <div class="d-flex flex-col mb-3">
            <div class="flex-shrink-1 py-3">
              <b-button
                variant="primary"
                class="fs-1 px-4 lh-base"
                @click="
                  openDetailModal(
                    $t('ConfigureDishIngredients_Toppings_ModalTitle'),
                    toppingsCategories,
                    inventory.bowls.toppings,
                    currentBowl.toppings,
                    (item) => item?.price.toString(),
                    isActiveTopping,
                    selectTopping,
                    true
                  )
                "
              >
                <i-mdi-plus-thick class="large-inline" v-if="(currentDish.bowl.toppings || []).length === 0" />
                <i-mdi-pencil class="large-inline" v-else />
              </b-button>
            </div>
            <div>
              <div class="p-3 d-flex flex-wrap fs-5">
                <b-badge variant="light" v-for="topping in currentToppings()" class="ingredient-badge">
                  <img v-if="topping.uri" :src="topping.uri" alt="" />
                  {{ $forCurrentLang(topping.displayName) }}
                  <span class="d-inline-block" v-if="topping.price > 0">+ {{ (topping.price / 100).toFixed(2) }} CHF</span>
                </b-badge>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-6 col-md-6 sauce">
        <div class="row">
          <h4>{{ $t("ConfigureDishIngredients_Sauces_Title") }}</h4>
          <div class="d-flex flex-col">
            <div class="flex-shrink-1 py-3">
              <b-button
                variant="primary"
                class="fs-1 px-4 lh-base"
                @click="openDetailModal(sauceCategory, sauceCategory, inventory.bowls.sauces, currentBowl.sauce, () => null, isActiveSauce, selectSauce)"
              >
                <i-mdi-plus-thick class="large-inline" v-if="!currentDish.bowl.sauce" />
                <i-mdi-pencil class="large-inline" v-else />
              </b-button>
            </div>
            <div class="align-self-center">
              <div class="p-3 d-flex flex-wrap fs-4">
                <b-badge variant="light" class="ingredient-badge" v-if="currentBowl.sauce">
                  <img v-if="currentBowl.sauce.uri" :src="currentBowl.sauce.uri" alt="" />
                  {{ $forCurrentLang(currentBowl.sauce.displayName) }}
                  <div v-if="currentBowl.sauce.price > 0">+ {{ (currentBowl.sauce.price / 100).toFixed(2) }} CHF</div>
                </b-badge>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <b-alert :variant="allIngredientsUsed && sauceSelected ? 'success' : isValid ? 'info' : 'warning'" :model-value="true" class="mb-2 mb-md-5">
      <template v-if="!allIngredientsUsed && remainingIngredientsCount > 1">
        <strong>{{ $t("ConfigureDishIngredients_IngredientsCountLeft", { ingredients: remainingIngredientsCount }) }}</strong>
      </template>
      <template v-else-if="!allIngredientsUsed && remainingIngredientsCount === 1">
        <strong>{{ $t("ConfigureDishIngredients_IngredientsCountLeftSingular") }}</strong>
      </template>
      <template v-else>
        <strong>{{ $t("ConfigureDishIngredients_AllIngredientsSelected") }}</strong>
      </template>
      <template v-if="!sauceSelected">
        <strong>{{ $t("ConfigureDishIngredients_SauceNotSelected") }}</strong>
      </template>
      <template v-else>
        <strong>{{ $t("ConfigureDishIngredients_SauceSelected") }}</strong>
      </template>
    </b-alert>

    <div class="text-end fs-3 mb-5 mt-3">
      <div v-html="$t('ConfigureDish_Price', { price: priceCalculator.calculatePrice(currentDish) })"></div>
    </div>

    <div class="d-flex justify-content-between mb-3">
      <b-button
        variant="outline-primary"
        class="me-3"
        size="lg"
        @click="backToBaseSelection()"
        v-html="$t('ConfigureDishIngredients_BackToBase_Label')"
      ></b-button>
      <b-button variant="primary" :disabled="!isValid" size="lg" @click="finish()">{{ $t("ConfigureDish_Finish_Label") }}</b-button>
    </div>

    <b-modal
      :title="selectedCategory ? $forCurrentLang(selectedCategory.displayName) : ingredientDetailModalTitle"
      v-model="showIngredientDetailModal"
      size="lg"
      :ok-only="true"
      :ok-title="$t('ConfigureDishIngredients_Modal_Close')"
      :class="{ shaking: shakeDialog }"
      :scrollable="true"
    >
      <template v-if="showIngredientDetailModal">
        <template v-if="needsAccordion(selectedCategoryIngredientCategories)">
          <b-accordion free>
            <b-accordion-item
              v-for="(ingredientCategory, index) in selectedCategoryIngredientCategories"
              :key="ingredientCategory.name"
              :visible="selectedCategoryFirstCategoryOpen && index === 0"
            >
              <template #title="">
                {{ $forCurrentLang(ingredientCategory.displayName) }}
                <template v-if="itemsInCategory(ingredientCategory.name, selectedCategoryItemsOnDish) > 0">
                  &nbsp;<b-badge variant="primary" pill>{{ itemsInCategory(ingredientCategory.name, selectedCategoryItemsOnDish) }}</b-badge>
                </template>
              </template>
              <b-row>
                <b-col cols="12" md="6" v-for="ingredient in getItemsInCategory(ingredientCategory.name, selectedCategoryItems)">
                  <div
                    class="item"
                    :class="{ active: selectedCategoryIsActive(ingredient.name), disabled: allIngredientsUsed }"
                    @click="selectedCategoryToggleActive(ingredient)"
                  >
                    <img v-if="ingredient.uri" :src="ingredient.uri" alt="" class="w-25" />
                    <div class="d-inline-block text-start ps-3">
                      {{ $forCurrentLang(ingredient.displayName) }}
                      <template v-if="ingredient.description">
                        <br />
                        <small>{{ $forCurrentLang(ingredient.description) }}</small>
                      </template>
                      <div v-if="ingredient.price > 0">+ {{ (ingredient.price / 100).toFixed(2) }} CHF</div>
                    </div>
                  </div>
                </b-col>
              </b-row>
            </b-accordion-item>
          </b-accordion>
        </template>
        <template v-else>
          <b-row>
            <b-col cols="12" md="6" v-for="ingredient in selectedCategoryItems">
              <div
                class="item"
                :class="{ active: selectedCategoryIsActive(ingredient.name), disabled: allIngredientsUsed }"
                @click="selectedCategoryToggleActive(ingredient)"
              >
                <img v-if="ingredient.uri" :src="ingredient.uri" alt="" class="w-25" />
                <div class="d-inline-block text-start ps-3">
                  {{ $forCurrentLang(ingredient.displayName) }}
                  <template v-if="ingredient.description">
                    <br />
                    <small>{{ $forCurrentLang(ingredient.description) }}</small>
                  </template>
                  <div v-if="ingredient.price > 0">+ {{ (ingredient.price / 100).toFixed(2) }} CHF</div>
                </div>
              </div>
            </b-col>
          </b-row>
        </template>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { toRefs, ref, computed } from "vue";
import { useInventoryStore } from "../../stores/inventory";
import { useOrderStore } from "../../stores/order";
import { PriceCalculatorService } from "../../services/price-calculator-service";
import checked from "$icons/checked.svg";

export default {
  props: {
    dishId: {
      type: Number,
      required: true,
    },
  },
  components: {
    checked,
  },
  setup(props) {
    // Props
    const { dishId } = toRefs(props);

    // Stores
    const inventoryStore = useInventoryStore();
    const orderStore = useOrderStore();
    const priceCalculator = new PriceCalculatorService();

    // Refs
    const currentDishId = ref(dishId.value);
    const inventory = ref(inventoryStore.availableDishes);
    const order = ref(orderStore.getCurrentOrder);

    const currentDish = computed(() => {
      return orderStore.getMenu(currentDishId.value);
    });

    const currentBowl = computed(() => {
      return currentDish.value.bowl;
    });

    const bowlSize = computed(() => {
      return inventory.value.bowls.sizes.find((size) => size.$type === currentDish.value.bowl.size.$type);
    });

    const premixed = computed(() => {
      return inventory.value.bowls.premixedBowls.filter(
        (bowl) => bowl.base.name === currentDish.value.bowl.base.name && bowl.size.$type === currentDish.value.bowl.size.$type
      );
    });

    const remainingIngredientsCount = computed(() => {
      return bowlSize.value.ingredients - (currentDish.value.bowl.ingredients?.length || 0);
    });

    const allIngredientsUsed = computed(() => {
      return remainingIngredientsCount.value === 0;
    });

    const sauceSelected = computed(() => {
      return !!currentDish.value.bowl.sauce;
    });

    const isValid = computed(() => {
      return currentDish.value.bowl.ingredients?.length > 0 && currentDish.value.bowl.sauce;
    });

    const customBowl = {
      name: "custom",
      displayName: {
        de: "Eigenkreation",
        en: "Custom bowl",
      },
      preserveItems: true,
    };

    const ingredientsCategories = [
      {
        name: "Vegetable",
        displayName: {
          de: "Gemüse",
          en: "Vegetables",
        },
      },
      {
        name: "Fruit",
        displayName: {
          de: "Früchte",
          en: "Fruit",
        },
      },
      {
        name: "Nut",
        displayName: {
          de: "Nüsse & Samen",
          en: "Nuts & Seeds",
        },
      },
      {
        name: "Herb",
        displayName: {
          de: "Kräuter",
          en: "Herbs",
        },
      },
    ];

    const toppingsCategories = [
      {
        name: "150",
        displayName: {
          de: "Toppings für 1.50 CHF",
          en: "Toppings for 1.50 CHF",
        },
      },
      {
        name: "250",
        displayName: {
          de: "Toppings für 2.50 CHF",
          en: "Toppings for 2.50 CHF",
        },
      },
    ];

    const sauceCategory = {
      name: "Sauce",
      displayName: {
        de: "Sauce",
        en: "Sauce",
      },
    };

    return {
      currentDishId,
      currentDish,
      currentBowl,
      bowlSize,

      inventory,
      premixed,
      customBowl,
      ingredientsCategories,
      toppingsCategories,
      sauceCategory,

      showIngredientDetailModal: ref(false),
      ingredientDetailModalTitle: ref(""),
      selectedCategory: ref(null),
      modalCategories: ref([]),
      selectedCategoryFirstCategoryOpen: ref(false),
      selectedCategoryIngredientCategories: ref([]),
      selectedCategoryItems: ref([]),
      selectedCategoryItemsOnDish: ref([]),
      selectedCategoryCategoryNameSelector: ref((item) => {}),
      getCategoryRelevantName: ref((item) => {}),
      selectedCategoryIsActive: ref((name) => {}),
      selectedCategoryToggleActive: ref((item) => {}),

      isValid,
      remainingIngredientsCount,
      allIngredientsUsed,
      sauceSelected,
      shakeDialog: ref(false),

      order,
      orderStore,
      priceCalculator,
    };
  },
  components: {},
  mounted() {
    this.$nextTick(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
  },
  methods: {
    isActiveIngredient(ingredientName) {
      if (!this.currentDish.bowl.ingredients || this.currentDish.bowl.ingredients.length === 0) {
        return false;
      }

      return this.currentDish.bowl.ingredients.map((i) => i.name).includes(ingredientName);
    },
    isActiveTopping(toppingName) {
      if (!this.currentDish.bowl.toppings || this.currentDish.bowl.toppings.length === 0) {
        return false;
      }

      return this.currentDish.bowl.toppings.map((i) => i.name).includes(toppingName);
    },
    isActiveSauce(sauceName) {
      if (!this.currentDish.bowl.sauce) {
        return false;
      }

      return this.currentDish.bowl.sauce.name === sauceName;
    },
    selectIngredient(ingredient) {
      if (this.isActiveIngredient(ingredient.name)) {
        this.currentDish.bowl.ingredients = this.currentDish.bowl.ingredients.filter((i) => i.name !== ingredient.name);
      } else {
        if (this.currentDish.bowl.ingredients === undefined) {
          this.currentDish.bowl.ingredients = [];
        }
        this.currentDish.bowl.ingredients.push(ingredient);
      }

      this.currentDish.bowl.name = this.customBowl.name;
      this.currentDish.bowl.displayName = this.customBowl.displayName;

      var ingredientsCount = this.currentDish.bowl.ingredients.length;
      this.validateAndFixCurrentDishIngredients();
      var newIngredientsCount = this.currentDish.bowl.ingredients.length;

      if (ingredientsCount !== newIngredientsCount) {
        this.shakeDialog = true;
        setTimeout(() => {
          this.shakeDialog = false;
        }, 500);
      }

      this.orderStore.updateMenu(this.currentDishId, this.currentDish);
    },
    selectTopping(topping) {
      if (this.isActiveTopping(topping.name)) {
        this.currentDish.bowl.toppings = this.currentDish.bowl.toppings.filter((i) => i.name !== topping.name);
      } else {
        if (this.currentDish.bowl.toppings === undefined) {
          this.currentDish.bowl.toppings = [];
        }
        this.currentDish.bowl.toppings.push(topping);
      }

      this.currentDish.bowl.name = this.customBowl.name;
      this.currentDish.bowl.displayName = this.customBowl.displayName;
      this.orderStore.updateMenu(this.currentDishId, this.currentDish);
    },
    selectSauce(sauce) {
      this.currentDish.bowl.sauce = sauce;
      this.currentDish.bowl.name = this.customBowl.name;

      this.orderStore.updateMenu(this.currentDishId, this.currentDish);
    },
    openDetailModal(categoryOrTitle, ingredientCategories, items, itemsOnDish, categoryNameSelector, isActiveItem, toggleItem, openFirstCategory = false) {
      if (typeof categoryOrTitle === "string") {
        this.ingredientDetailModalTitle = categoryOrTitle;
      } else {
        this.selectedCategory = categoryOrTitle;
      }

      this.selectedCategoryFirstCategoryOpen = openFirstCategory;
      this.selectedCategoryIngredientCategories = ingredientCategories;
      this.selectedCategoryItems = items;
      this.selectedCategoryItemsOnDish = Array.isArray(itemsOnDish) ? itemsOnDish : [itemsOnDish];
      this.selectedCategoryCategoryNameSelector = categoryNameSelector;
      this.selectedCategoryIsActive = isActiveItem;
      this.selectedCategoryToggleActive = toggleItem;

      this.showIngredientDetailModal = true;
    },
    closeDetailModal() {
      this.showIngredientDetailModal = false;
    },
    needsAccordion(categoryItems) {
      return categoryItems.length >= 2;
    },
    currentMixIns() {
      return this.currentDish.bowl.ingredients;
    },
    currentToppings() {
      return this.currentDish.bowl.toppings;
    },
    currentDishIngredients(category) {
      if (!this.currentDish.bowl.ingredients) {
        return [];
      }

      return this.currentDish.bowl.ingredients.filter((ingredient) => ingredient.category === category);
    },
    itemsInCategory(category, items) {
      return this.getItemsInCategory(category, items).length || 0;
    },
    getItemsInCategory(category, items) {
      if (items === undefined || items === null || items.length === 0) {
        return [];
      }

      return items?.filter((item) => this.selectedCategoryCategoryNameSelector(item) === category) || [];
    },
    selectBowl(premixedBowl) {
      this.currentDish.bowl.name = premixedBowl.name;
      this.currentDish.bowl.displayName = premixedBowl.displayName;

      if (!premixedBowl.preserveItems) {
        this.currentDish.bowl.sauce = premixedBowl.sauce;
        this.currentDish.bowl.ingredients = premixedBowl.ingredients;
        this.currentDish.bowl.toppings = premixedBowl.toppings;
      }

      this.validateAndFixCurrentDishIngredients();

      this.orderStore.updateMenu(this.currentDishId, this.currentDish);
    },
    validateAndFixCurrentDishIngredients() {
      const maxIngredients = this.bowlSize.ingredients;
      while ((this.currentDish.bowl.ingredients?.length || 0) > maxIngredients) {
        this.currentDish.bowl.ingredients.pop();
      }
    },
    backToBaseSelection() {
      this.$router.push({
        name: "ConfigureExisting",
        params: { id: this.currentDishId, type: "bowl" },
      });
    },
    finish() {
      this.validateAndFixCurrentDishIngredients();

      this.$router.push({ name: "Menues" });
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/variables";

.shaking {
  animation: shake 0.5s;
}

@keyframes shake {
  0% {
    transform: translate(0, 0);
  }
  10%,
  30%,
  50%,
  70%,
  90% {
    transform: translate(-5px, 0);
  }
  20%,
  40%,
  60%,
  80% {
    transform: translate(5px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

.bowl-ingredients {
  .current-base {
    img {
      width: 75%;
    }
  }

  .ingredients {
    .add-ingredient-btn {
    }
    .item {
      min-height: 70px;
    }
  }

  .check-icon {
    width: 300px;
    height: 300px;
    display: inline-block;
  }
}
</style>
