import { createApp } from "vue";
import { createPinia } from "pinia";
import BootstrapVue3 from "bootstrap-vue-3";
import GlobalComponents from "./globals";

// Import stores
import { useAuthStore } from "./stores/auth";
import { useInventoryStore } from "./stores/inventory";
import { useOrderStore } from "./stores/order";
import { useSettingsStore } from "./stores/settings";

import App from "./App.vue";
import router from "./router";

/* Localization */
import { I18nService } from "./services/localization/i18n-service";
import { forCurrentLang } from "./services/localization/localization-helpers";

/* Styling */
import "@/scss/styles.scss";

const app = createApp(App);

if (import.meta.env.DEV) {
  app.config.devtools = true;
}

const pinia = createPinia();

app.use(pinia);
app.use(BootstrapVue3);
app.use(router);

const i18n = I18nService.setupI18n();
app.use(i18n);
app.config.globalProperties.$forCurrentLang = forCurrentLang;

app.use(GlobalComponents);

const authStore = useAuthStore();
authStore.initialize();

const inventoryStore = useInventoryStore();
inventoryStore.initialize();

const orderStore = useOrderStore();
orderStore.initialize();

const settingsStore = useSettingsStore();
settingsStore.loadSettings();
settingsStore.initializeAutoRefresh();

app.mount("#app");
