<template>
  <div
    :class="[
      'animated',
      {
        'animation-start': animateEntrance,
        'click-animate': animateOnClick,
        'animation-finish': animationFinished,
      },
    ]"
    @click="clickAnimate"
    @touchstart="clickAnimate"
  >
    <slot />
  </div>
</template>

<script>
import { ref, toRefs } from "vue";

export default {
  props: {
    delay: {
      type: Number,
      required: true,
    },
    enableClickAnimation: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { delay, enableClickAnimation } = toRefs(props);

    const animateEntrance = ref(false);
    const animateOnClick = ref(false);
    const animationFinished = ref(false);

    return {
      delay,
      enableClickAnimation,
      animateEntrance,
      animateOnClick,
      animationFinished,
    };
  },
  mounted() {
    if (this.delay > 0) {
      setTimeout(() => {
        this.animateEntrance = true;

        setTimeout(() => {
          this.animateEntrance = false;
          this.animationFinished = true;
        }, 500);
      }, this.delay);
    } else {
      this.animateEntrance = true;

      setTimeout(() => {
        this.animateEntrance = false;
        this.animationFinished = true;
      }, 500);
    }
  },
  methods: {
    clickAnimate() {
      if (!this.enableClickAnimation) {
        return;
      }

      this.animateOnClick = true;
      setTimeout(() => {
        this.animateOnClick = false;
      }, 500);
    },
  },
};
</script>

<style lang="scss">
.animated {
  width: 100%;
  height: 100%;

  -webkit-perspective: 1000;

  > * {
    opacity: 0;
    transform: translateZ(-1400px);
  }

  &.animation-finish {
    > * {
      opacity: 1;
      transform: translateZ(0);
    }
  }

  &.animation-start {
    > * {
      animation: slide-in-fwd-center 0.5s cubic-bezier(0.68, -0.55, 0.265, 1.55)
        both;
    }
  }

  &.click-animate {
    > * {
      animation: click-animation 0.25s ease-in-out both;
    }
  }
}

@keyframes slide-in-fwd-center {
  0% {
    transform: translateZ(-1400px);
    opacity: 0;
  }

  100% {
    transform: translateZ(0);
    opacity: 1;
  }
}

@keyframes click-animation {
  0% {
    transform: translateZ(0);
  }

  50% {
    transform: translateZ(-700px);
  }

  100% {
    transform: translateZ(0);
  }
}
</style>
