import { mande } from "mande";
const BASE_URL = import.meta.env.VITE_SERVICES_BASEURL;

const orders = mande(BASE_URL + "/api/orders");

export async function submitOrder(order) {
  try {
    return await orders.post("", order);
  } catch (error) {
    return false;
  }
}

export async function setPaymentSuccessful(orderId) {
  try {
    await orders.post(`paymentSuccessful?id=${orderId}`);
    return true;
  } catch (error) {
    return false;
  }
}
