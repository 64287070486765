<template>
  <div class="dish-bowl">
    <AnimateContent :delay="0" :enableClickAnimation="enableClickAnimation">
      <Salad viewBox="0 0 512 512" class="dish-icon" />
      <Salad viewBox="0 0 512 512" class="dish-icon filter-overlay" />
    </AnimateContent>
  </div>
</template>

<script>
import { toRefs } from "vue";

import Salad from "$icons/salad.svg";

export default {
  props: {
    enableClickAnimation: {
      Type: Object,
      default: null,
    },
  },
  setup(props) {
    const { enableClickAnimation } = toRefs(props);

    return {
      enableClickAnimation,
    };
  },
  components: {
    Salad,
  },
};
</script>

<style lang="scss">
.dish-bowl {
  position: relative;
  width: 100%;
  height: 100%;

  .dish-icon {
    display: block;
    width: 100%;
    height: 100%;
  }

  .filter-overlay {
    filter: brightness(0) saturate(100%) invert(71%) sepia(73%) saturate(433%) hue-rotate(40deg) brightness(88%) contrast(95%) opacity(15%);
    display: block;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
}
</style>
