<script setup></script>

<template>
  <main>
    <h1 class="page-title" v-html="$t('Home_Intro_TitleHtml')"></h1>
    <p class="mb-5" v-html="$t('Home_Intro_TextHtml')"></p>
    <div class="d-grid gap-2 mb-5">
      <b-button to="/order" variant="primary" size="lg">{{ $t("Home_OrderButton_Label") }}</b-button>
    </div>
    <div class="d-grid gap-2"></div>
  </main>
</template>

<style lang="scss">
.page-title {
  line-height: 0.8;
  margin-bottom: 2rem;
}
.order-image {
  border-bottom-right-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  padding: 0 !important;

  img {
    max-width: 100%;
    height: auto;
  }
}
</style>
