<template>
  <h1>{{ $t("ConfigureDish_Drinks_Title") }}</h1>
  <p>{{ $t("ConfigureDish_Drinks_Lead") }}</p>
  <div class="row mb-5">
    <div v-for="drink in inventory.drinks.drinks" :key="drink.id" class="col-6 col-md-4 col-lg-3" @click="selectDrink(drink)">
      <div class="item text-center" :class="{ active: currentDish.drink?.$type === drink.$type }">
        <AnimateContent :delay="0" class="mb-3">
          <img :src="drink.uri" alt="" />
        </AnimateContent>
        <h5>{{ $forCurrentLang(drink.displayName) }}</h5>
        <div v-if="drink.price > 0">{{ (drink.price / 100).toFixed(2) }} CHF</div>
      </div>
    </div>
  </div>

  <template v-if="currentDish.drink">
    <div id="Finish" class="row mb-5">
      <div class="col">
        <b-button variant="primary" size="lg" class="float-end" @click="finish()" v-html="$t('ConfigureDish_Finish_Label')"></b-button>
      </div>
    </div>
  </template>
</template>

<script>
import { toRefs, ref, computed } from "vue";
import { useInventoryStore } from "../../stores/inventory";
import { useOrderStore } from "../../stores/order";

export default {
  props: {
    dishId: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    // Props
    const { dishId } = toRefs(props);

    // Stores
    const inventoryStore = useInventoryStore();
    const orderStore = useOrderStore();

    // Refs
    const currentDishId = ref(dishId.value);
    const inventory = ref(inventoryStore.availableDishes);
    const order = ref(orderStore.getCurrentOrder);

    const currentDish = computed(() => {
      if (currentDishId.value > 0) {
        return orderStore.getMenu(currentDishId.value);
      } else {
        return {};
      }
    });

    return {
      currentDishId,
      currentDish,

      inventory,
      order,
      orderStore,
    };
  },
  methods: {
    selectDrink(drink) {
      if (this.currentDishId > 0) {
        let dish = this.orderStore.getMenu(this.currentDishId);

        dish.drink = drink;

        this.orderStore.updateMenu(this.currentDishId, dish);
      } else {
        this.currentDishId = this.orderStore.addMenu({
          drink: drink,
        });

        this.$router.push({
          name: "ConfigureExisting",
          params: { id: this.currentDishId, type: "drink" },
        });
      }

      this.$nextTick(() => {
        document.getElementById("Finish").scrollIntoView({
          behavior: "smooth",
        });
      });
    },
    finish() {
      this.$router.push({ name: "Menues" });
    },
  },
};
</script>

<style lang="scss">
.dish-cubes {
  position: relative;

  .filter-overlay {
    filter: brightness(0) saturate(100%) invert(71%) sepia(73%) saturate(433%) hue-rotate(40deg) brightness(88%) contrast(95%) opacity(15%);
    display: block;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
}
</style>
