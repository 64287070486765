<template>
  <h1>{{ $t("ConfigureDish_Cubes_Title") }}</h1>
  <p>{{ $t("ConfigureDish_Cubes_Lead") }}</p>
  <div class="row mb-5">
    <div v-for="size in inventory.cubes.sizes" :key="size.id" class="col-6 col-md-4 col-lg-3" @click="selectSize(size)">
      <div class="item text-center" :class="{ active: currentDish.cube?.size?.$type === size.$type }">
        <AnimateContent :delay="0" class="mb-3">
          <img :src="size.uri" alt="" />
        </AnimateContent>
        <h5>{{ $forCurrentLang(size.displayName) }}</h5>
      </div>
    </div>
  </div>

  <template v-if="currentDish.cube?.size">
    <h2 id="SauceSelection">{{ $t("ConfigureDish_Cubes_Sauce_Title") }}</h2>
    <p>{{ $t("ConfigureDish_Cubes_Sauce_Lead") }}</p>

    <div class="row mb-5">
      <div v-for="sauce in inventory.cubes.sauces" :key="sauce.id" class="col-6 col-md-4 col-lg-4" @click="selectSauce(sauce)">
        <div class="item flex" :class="{ active: currentDish.cube?.sauce?.$type === sauce.$type }">
          <img v-if="sauce.uri" :src="sauce.uri" alt="" class="w-25" />
          <div class="d-inline-block text-start ps-3">
            <p class="mb-0 pb-0">
              <strong>{{ $forCurrentLang(sauce.displayName) }}</strong>
              <template v-if="sauce.description">
                <br />
                <small>{{ $forCurrentLang(sauce.description) }}</small>
              </template>
            </p>
            <div v-if="sauce.price > 0">+ {{ (sauce.price / 100).toFixed(2) }} CHF</div>
          </div>
        </div>
      </div>
    </div>
  </template>

  <div class="text-end fs-3 mb-5">
    <div v-html="$t('ConfigureDish_Price', { price: priceCalculator.calculatePrice(currentDish) })"></div>
  </div>

  <template v-if="currentDish.cube?.size && currentDish.cube?.sauce">
    <div id="Finish" class="row mb-5">
      <div class="col">
        <b-button variant="primary" size="lg" class="float-end" @click="finish()" v-html="$t('ConfigureDish_Finish_Label')"></b-button>
      </div>
    </div>
  </template>
</template>

<script>
import { toRefs, ref, computed } from "vue";
import { useInventoryStore } from "../../stores/inventory";
import { useOrderStore } from "../../stores/order";
import { PriceCalculatorService } from "../../services/price-calculator-service";

export default {
  props: {
    dishId: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    // Props
    const { dishId } = toRefs(props);

    // Stores
    const inventoryStore = useInventoryStore();
    const orderStore = useOrderStore();
    const priceCalculator = new PriceCalculatorService();

    // Refs
    const currentDishId = ref(dishId.value);
    const inventory = ref(inventoryStore.availableDishes);
    const order = ref(orderStore.getCurrentOrder);

    const currentDish = computed(() => {
      if (currentDishId.value > 0) {
        return orderStore.getMenu(currentDishId.value);
      } else {
        return {};
      }
    });

    return {
      currentDishId,
      currentDish,

      inventory,
      order,
      orderStore,
      priceCalculator,
    };
  },
  methods: {
    selectSize(cubeSize) {
      if (this.currentDishId > 0) {
        let dish = this.orderStore.getMenu(this.currentDishId);

        dish.cube.size = cubeSize;

        this.orderStore.updateMenu(this.currentDishId, dish);
      } else {
        this.currentDishId = this.orderStore.addMenu({
          cube: {
            size: cubeSize,
          },
        });

        this.$router.push({
          name: "ConfigureExisting",
          params: { id: this.currentDishId, type: "cube" },
        });
      }

      this.$nextTick(() => {
        document.getElementById("SauceSelection").scrollIntoView({
          behavior: "smooth",
        });
      });
    },
    selectSauce(sauce) {
      let dish = this.orderStore.getMenu(this.currentDishId);
      dish.cube.sauce = sauce;

      this.orderStore.updateMenu(this.currentDishId, dish);

      this.$nextTick(() => {
        document.getElementById("Finish").scrollIntoView({
          behavior: "smooth",
        });
      });
    },
    finish() {
      this.$router.push({ name: "Menues" });
    },
  },
};
</script>

<style lang="scss">
.dish-cubes {
  position: relative;

  .filter-overlay {
    filter: brightness(0) saturate(100%) invert(71%) sepia(73%) saturate(433%) hue-rotate(40deg) brightness(88%) contrast(95%) opacity(15%);
    display: block;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
}
</style>
