import { defineStore } from "pinia";
import { loadInventory } from "../services/inventory-service.js";

export const useInventoryStore = defineStore("inventory", {
  state: () => {
    return {
      isLoading: false,
      isInitialized: false,

      bowlsAvailable: false,
      cubesAvailable: false,
      soupsAvailable: false,

      bowlBases: [],
      bowlIngredients: [],
      bowlSauces: [],
      bowlSizes: [],

      premixedBowls: [],

      cubeSizes: [],
      cubeSauces: [],

      soups: [],

      drinks: [],
    };
  },

  getters: {
    isInventoryAvailable: (state) => state.isInitialized && !state.isLoading,
    availableDishes: (state) => {
      return {
        bowls: {
          type: "bowl",
          isAvailable: state.bowlsAvailable,
          bases: state.bowlBases,
          sizes: state.bowlSizes,
          ingredients: state.bowlIngredients,
          toppings: state.bowlToppings,
          sauces: state.bowlSauces,
          premixedBowls: state.premixedBowls,
        },
        cubes: {
          type: "cube",
          isAvailable: state.cubesAvailable,
          sizes: state.cubeSizes,
          sauces: state.cubeSauces,
        },
        soups: {
          type: "soup",
          isAvailable: state.soupsAvailable,
          soupTypes: state.soupTypes,
          soupSizes: state.soupSizes,
        },
        drinks: {
          type: "drink",
          isAvailable: state.drinksAvailable,
          drinks: state.drinks,
        },
      };
    },
  },

  actions: {
    async loadInventory() {
      this.isLoading = true;
      const inventoryData = await loadInventory();

      this.bowlsAvailable = inventoryData.bowlBases?.find((b) => b.isAvailable) !== undefined;
      this.cubesAvailable = inventoryData.cubeSizes?.find((c) => c.isAvailable) !== undefined;
      this.drinksAvailable = inventoryData.drinks?.find((s) => s.isAvailable) !== undefined;
      this.soupsAvailable =
        inventoryData.soupTypes?.find((s) => s.isAvailable) !== undefined && inventoryData.soupSizes?.find((s) => s.isAvailable) !== undefined;

      this.bowlBases = inventoryData.bowlBases.filter((x) => x.isAvailable);
      this.bowlIngredients = inventoryData.bowlIngredients.filter((x) => x.isAvailable);
      this.bowlToppings = inventoryData.bowlToppings.filter((x) => x.isAvailable);
      this.bowlSauces = inventoryData.bowlSauces.filter((x) => x.isAvailable);
      this.bowlSizes = inventoryData.bowlSizes.filter((x) => x.isAvailable);
      this.premixedBowls = inventoryData.premixedBowls.filter((x) => x.isAvailable);

      this.cubeSizes = inventoryData.cubeSizes.filter((x) => x.isAvailable);
      this.cubeSauces = inventoryData.cubeSauces.filter((x) => x.isAvailable);

      this.soupTypes = inventoryData.soupTypes.filter((x) => x.isAvailable);
      this.soupSizes = inventoryData.soupSizes.filter((x) => x.isAvailable);

      this.drinks = inventoryData.drinks.filter((x) => x.isAvailable);

      this.isLoading = false;
      this.isInitialized = true;
    },
    async initialize() {
      await this.loadInventory();
    },
  },
});
