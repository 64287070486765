<template>
  <h1 id="PageTitle" v-if="hitBowls.length > 0">{{ $t("ConfigureDish_Bowl_Title") }}</h1>
  <h1 id="PageTitle" v-else>{{ $t("ConfigureDish_Bolw_NoHitTitle") }}</h1>
  <div v-if="hitBowls.length > 0" class="row mb-5">
    <div v-for="hit in hitBowls" :key="hit.id" class="col-12" @click="selectHit(hit)">
      <div class="item item-hit">
        <b-row>
          <b-col cols="3" class="align-self-center">
            <AnimateContent :delay="0">
              <img :src="hit.uri?.length > 0 ?? false ? hit.uri : hit.base.uri" alt="" />
            </AnimateContent>
          </b-col>
          <b-col cols="9">
            <h5 class="fs-6 mb-0">{{ $forCurrentLang(hit.displayName) }}</h5>
            <div class="hit-content">
              <p class="mb-0">
                <strong>{{ $t("ConfigureDish_Bowl_Hit_Base") }}:</strong> {{ $forCurrentLang(hit.base.displayName) }} ({{ $t("ConfigureDish_Bowl_Hit_Size") }}
                {{ $forCurrentLang(hit.size.displayName) }})
              </p>
              <p class="mb-0">
                <strong>{{ $t("ConfigureDish_Bowl_Hit_Ingredients") }}:</strong>
                {{
                  hit.ingredients.reduce(
                    (acc, curr, index) => (acc += $forCurrentLang(curr.displayName) + (index + 1 < hit.ingredients.length ? ", " : "")),
                    ""
                  )
                }}
              </p>
              <p class="mb-0">
                <strong>{{ $t("ConfigureDish_Bowl_Hit_Sauce") }}:</strong> {{ $forCurrentLang(hit.sauce.displayName) }}
              </p>
              <p class="mb-2">
                <strong>{{ $t("ConfigureDish_Bowl_Hit_Toppings") }}:</strong>
                {{ hit.toppings.reduce((acc, curr, index) => (acc += $forCurrentLang(curr.displayName) + (index + 1 < hit.toppings.length ? ", " : "")), "") }}
              </p>
              <div class="text-end fs-6">
                <div v-html="$t('ConfigureDish_Price', { price: priceCalculator.calculatePrice(hit) })"></div>
              </div>
            </div>
          </b-col>
        </b-row>
      </div>
    </div>
  </div>
  <h2 v-if="hitBowls.length > 0">{{ $t("ConfigureDish_Bowl_Base_Title") }}</h2>
  <p>{{ $t("ConfigureDish_Bowl_Base_Lead") }}</p>
  <div class="row mb-5">
    <div v-for="base in inventory.bowls.bases" :key="base.id" class="col-6 col-md-4 col-lg-3" @click="selectBase(base)">
      <div class="item text-center" :class="{ active: currentDish.bowl?.base.$type === base.$type }">
        <AnimateContent :delay="0" class="mb-3">
          <img :src="base.uri" alt="" />
        </AnimateContent>
        <h5>{{ $forCurrentLang(base.displayName) }}</h5>
      </div>
    </div>
  </div>

  <template v-if="currentDish.bowl?.base">
    <h2 id="SizeSelection">{{ $t("ConfigureDish_Bowl_Size_Title") }}</h2>
    <p>{{ $t("ConfigureDish_Bowl_Size_Lead") }}</p>

    <div class="row mb-5">
      <div v-for="size in inventory.bowls.sizes" :key="size.id" class="col" @click="selectSize(size)">
        <div class="item text-center" :class="{ active: currentDish.bowl?.size?.$type === size.$type }">
          <h5 class="card-title">{{ $forCurrentLang(size.displayName) }}</h5>
          <p class="mt-2 mb-0">
            {{
              size.ingredients == 1
                ? $t("ConfigureDish_Bowl_Size_IngredientsSingular", { ingredients: size.ingredients })
                : $t("ConfigureDish_Bowl_Size_IngredientsPlural", { ingredients: size.ingredients })
            }}
          </p>
        </div>
      </div>
    </div>
  </template>

  <template v-if="currentDish.bowl?.base && currentDish.bowl?.size">
    <div class="text-end fs-3 mb-5">
      <div v-html="$t('ConfigureDish_Price', { price: priceCalculator.calculatePrice(currentDish) })"></div>
    </div>

    <div id="NextStep" class="row mb-5">
      <div class="col">
        <b-button variant="primary" size="lg" class="float-end" @click="goToIngredients()" v-html="$t('ConfigureDish_Bowl_NextButton_Label')"></b-button>
      </div>
    </div>
  </template>
</template>

<script>
import { toRefs, ref, computed } from "vue";
import { useInventoryStore } from "../../stores/inventory";
import { useOrderStore } from "../../stores/order";
import { PriceCalculatorService } from "../../services/price-calculator-service";

export default {
  props: {
    dishId: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    // Props
    const { dishId } = toRefs(props);

    // Stores
    const inventoryStore = useInventoryStore();
    const orderStore = useOrderStore();
    const priceCalculator = new PriceCalculatorService();

    // Refs
    const currentDishId = ref(dishId.value);
    const inventory = ref(inventoryStore.availableDishes);
    const order = ref(orderStore.getCurrentOrder);

    const currentDish = computed(() => {
      if (currentDishId.value > 0) {
        return orderStore.getMenu(currentDishId.value);
      } else {
        return {};
      }
    });

    return {
      priceCalculator,
      currentDishId,
      currentDish,

      inventory,
      order,
      orderStore,
    };
  },
  components: {},
  computed: {
    hitBowls() {
      // Return all bowls that have isHit set to true.
      if (this.inventory.bowls.premixedBowls.some((bowl) => bowl.isHit === true)) {
        return this.inventory.bowls.premixedBowls.filter((bowl) => bowl.isHit ?? true).slice(0, 3);
      }

      return [];
    },
  },
  mounted() {
    this.$nextTick(() => {
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    });
  },
  methods: {
    selectBase(base) {
      if (this.currentDishId > 0) {
        let dish = this.orderStore.getMenu(this.currentDishId);
        dish.bowl.base = base;

        this.orderStore.updateMenu(this.currentDishId, dish);
      } else {
        this.currentDishId = this.orderStore.addMenu({
          bowl: {
            base: base,
          },
        });

        this.$router.push({
          name: "ConfigureExisting",
          params: { id: this.currentDishId, type: "bowl" },
        });
      }

      this.$nextTick(() => {
        document.getElementById("SizeSelection").scrollIntoView({
          behavior: "smooth",
        });
      });
    },
    selectHit(hit) {
      if (this.currentDishId > 0) {
        let dish = this.orderStore.getMenu(this.currentDishId);

        dish.bowl.name = hit.name;
        dish.bowl.displayName = hit.displayName;
        dish.bowl.base = hit.base;
        dish.bowl.ingredients = hit.ingredients;
        dish.bowl.sauce = hit.sauce;
        dish.bowl.size = hit.size;
        dish.bowl.toppings = hit.toppings;

        this.orderStore.updateMenu(this.currentDishId, dish);
      } else {
        this.currentDishId = this.orderStore.addMenu({
          bowl: {
            name: hit.name,
            displayName: hit.displayName,
            base: hit.base,
            ingredients: hit.ingredients,
            sauce: hit.sauce,
            size: hit.size,
            toppings: hit.toppings,
          },
        });

        this.$router.push({
          name: "ConfigureExisting",
          params: { id: this.currentDishId, type: "bowl" },
        });
      }

      this.goToIngredients();
    },
    selectSize(size) {
      let dish = this.orderStore.getMenu(this.currentDishId);
      dish.bowl.size = size;

      this.orderStore.updateMenu(this.currentDishId, dish);

      this.$nextTick(() => {
        document.getElementById("NextStep").scrollIntoView({
          behavior: "smooth",
        });
      });
    },
    goToIngredients() {
      this.$router.push({
        name: "ConfigureIngredients",
        params: { id: this.currentDishId, type: "bowl" },
      });
    },
  },
};
</script>

<style lang="scss">
.item {
  &.item-hit {
    text-align: left;

    .hit-content {
      font-size: 0.9rem;
    }
  }
}
</style>
