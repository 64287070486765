import { mande, defaults } from "mande";
const BASE_URL = import.meta.env.VITE_SERVICES_BASEURL;

const users = mande(BASE_URL + "/api/users");

export function loginUser(email, password) {
  return users.post("login", {
    email: email,
    password: password,
  });
}

export async function currentUserProfile() {
  return await users.get("profile")
    .catch(() => null);
}
