<template>
  <div class="dish-menu row" :class="{ 'layout-vertical': vertical }">
    <div class="col-6 dish dish-bowl mb-1" v-if="availableDishes.bowls.isAvailable">
      <b-button variant="outline-light" @click="selectDishType(availableDishes.bowls.type)">
        <img src="/assets/images/hauptmenu/bowl.jpg" class="dish-image" />
        <h4 class="text-dark" v-if="withLabel">{{ $t("DishMenu_Bowls_Label") }}</h4>
      </b-button>
    </div>
    <div class="col-6 dish dish-cubes mb-1" v-if="availableDishes.cubes.isAvailable">
      <b-button variant="outline-light" @click="selectDishType(availableDishes.cubes.type)">
        <img src="/assets/images/hauptmenu/cubes.jpg" class="dish-image" />
        <h4 class="text-dark" v-if="withLabel">{{ $t("DishMenu_Cubes_Label") }}</h4>
      </b-button>
    </div>
    <div class="col-6 dish dish-soups mb-1" v-if="availableDishes.soups.isAvailable">
      <b-button variant="outline-light" @click="selectDishType(availableDishes.soups.type)">
        <img src="/assets/images/hauptmenu/soup.jpg" class="dish-image" />
        <h4 class="text-dark" v-if="withLabel">{{ $t("DishMenu_Soups_Label") }}</h4>
      </b-button>
    </div>
    <div class="col-6 dish dish-drinks mb-1" v-if="availableDishes.drinks.isAvailable">
      <b-button variant="outline-light" @click="selectDishType(availableDishes.drinks.type)">
        <img src="/assets/images/hauptmenu/drinks.jpg" class="dish-image" />
        <h4 class="text-dark" v-if="withLabel">{{ $t("DishMenu_Drinks_Label") }}</h4>
      </b-button>
    </div>
  </div>
</template>

<script>
import { ref, toRefs } from "vue";
import { useInventoryStore } from "../../stores/inventory";

export default {
  name: "DishMenu",
  props: {
    vertical: {
      type: Boolean,
      default: false,
    },
    withLabel: {
      type: Boolean,
      default: true,
    },
  },
  components: {},
  setup(props) {
    // Props
    const { vertical, withLabel } = toRefs(props);

    const inventoryStore = useInventoryStore();
    const availableDishes = ref(inventoryStore.availableDishes);

    return {
      vertical,
      withLabel,
      availableDishes,
    };
  },
  methods: {
    selectDishType(type) {
      this.$emit("select-dish", { type: type });
    },
  },
};
</script>

<style lang="scss">
@import "@/scss/variables";

.dish-menu {
  display: flex;
  flex-direction: row;
  align-items: justify;
  justify-content: space-evenly;
  height: 100%;

  > .dish {
    .btn {
      width: 100%;

      .btn-content {
        width: 100%;

        .dish-image {
          width: 100%;
          height: 150px;
          object-fit: scale-down;
        }
      }
    }
  }

  &.layout-vertical {
    flex-direction: column;

    > .dish {
      width: 50%;
      margin-bottom: $grid-gutter-width;

      .btn {
        .btn-content {
          .dish-bowl,
          .dish-cubes,
          .dish-soups {
            height: 140px;
          }
        }
      }
    }
  }
}
</style>
