import { computed, nextTick } from "vue";
import { createI18n } from "vue-i18n";
import { loadTranslations } from "../settings-service";

import { defaults } from "mande";

class I18nService {
  static _i18n;

  static setupI18n() {
    this._i18n = createI18n({
      legacy: false,
      locale: import.meta.env.VITE_DEFAULTLANGUAGE,
      silentFallbackWarn: true,
      warnHtmlMessage: false,
      messages: {
        [import.meta.env.VITE_DEFAULTLANGUAGE]: {},
      },
    });
    this.setI18nLanguage(import.meta.env.VITE_DEFAULTLANGUAGE);

    this.getMessages(import.meta.env.VITE_DEFAULTLANGUAGE).then((messages) => {
      this._i18n.global.setLocaleMessage(import.meta.env.VITE_DEFAULTLANGUAGE, messages);
    });

    return this._i18n;
  }

  static setI18nLanguage(locale) {
    locale = this.normalizeLocale(locale);

    this._i18n.global.locale = computed(() => locale);
    defaults.headers["Accept-Language"] = locale;
    this.loadLocaleMessages(locale);
    document.querySelector("html")?.setAttribute("lang", locale);
  }

  static async loadLocaleMessages(locale) {
    locale = this.normalizeLocale(locale);
    const mainMessages = await this.getMessages(locale);

    // Get messages from settings service
    const settingsTranslations = await loadTranslations(); // Assuming this function returns a promise with the translations
    const parsedTranslations = settingsTranslations ? JSON.parse(settingsTranslations) : {};

    // Merge messages (settingsTranslations will override mainMessages where they overlap)
    const mergedMessages = { ...mainMessages, ...parsedTranslations };

    this._i18n.global.setLocaleMessage(locale, mergedMessages);

    return nextTick();
  }

  static normalizeLocale(locale) {
    if (locale && locale.length > 2) {
      locale = locale.substring(0, 2).toLowerCase();
    }

    return locale;
  }

  static async getMessages(locale) {
    locale = this.normalizeLocale(locale);
    return (await import(`../../assets/i18n/${locale}.json`)).default;
  }
}

export { I18nService };
