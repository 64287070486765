import { mande } from "mande";
const BASE_URL = import.meta.env.VITE_SERVICES_BASEURL;

const configuration = mande(BASE_URL + "/api");

export function loadSettings() {
  return configuration.get("/configuration?id=shop:settings");
}

export function loadTranslations() {
  return configuration.get("/configuration?id=shop:translations");
}
