import { defineStore } from "pinia";
import { submitOrder, setPaymentSuccessful } from "../services/order-service";
import moment from "moment";

export const constants = {
  pickupTypes: [
    {
      id: "DriveIn",
      validator: (order, settings) => true,
    },
    {
      id: "Store",
      validator: (order, settings) => true,
    },
    {
      id: "DeliveryVisp",
      validator: (order, settingsStore) => settingsStore.isInDeliveryHour && !order.menus.some((menu) => "soup" in menu || "cube" in menu),
    },
    {
      id: "DeliveryBrig",
      validator: (order, settingsStore) => settingsStore.isInDeliveryHour && !order.menus.some((menu) => "soup" in menu || "cube" in menu),
    },
  ],
  emptyOrder: {
    id: null,
    orderDate: null,
    firstName: null,
    lastName: null,
    email: null,
    mobile: null,
    pickup: null,
  },
};

export const useOrderStore = defineStore("order", {
  state: () => {
    return {
      currentOrder: Object.assign({ menus: [] }, constants.emptyOrder),
      successfulOrder: null,
    };
  },
  getters: {
    getCurrentOrder(state) {
      return state.currentOrder;
    },
    getPickupTypes(state) {
      return constants.pickupTypes;
    },
    getSuccessfulOrder(state) {
      return state.successfulOrder;
    },
  },
  actions: {
    addMenu(menuItem) {
      const menuId = this.currentOrder.menus.length + 1;
      menuItem.id = menuId;

      this.currentOrder.menus.push(menuItem);
      this.saveOrderToLocalStore(this.currentOrder);

      return menuId;
    },
    getMenu(menuId) {
      const index = this.currentOrder.menus.findIndex((m) => m.id === menuId);
      if (index === -1) {
        throw new Error(`Menu '${menuId}' not found`);
      }

      return this.currentOrder.menus[index];
    },
    updateMenu(menuId, menuItem) {
      const index = this.currentOrder.menus.findIndex((m) => m.id === menuId);
      if (index === -1) {
        throw new Error(`Menu '${menuId}' not found`);
      }

      this.currentOrder.menus[index] = menuItem;
      this.saveOrderToLocalStore(this.currentOrder);
    },
    removeMenu(menuId) {
      const index = this.currentOrder.menus.findIndex((m) => m.id === menuId);
      if (index === -1) {
        throw new Error(`Menu '${menuId}' not found`);
      }

      this.currentOrder.menus.splice(index, 1);
      this.saveOrderToLocalStore(this.currentOrder);
    },
    updateOrderData(orderData) {
      this.currentOrder = orderData;
      this.saveOrderToLocalStore(this.currentOrder);
    },
    resetOrder() {
      this.currentOrder = Object.assign({ menus: [] }, constants.emptyOrder);
      this.resetLocalStore();
    },
    executeOrder() {
      const currentOrder = { ...this.currentOrder };
      return submitOrder(currentOrder);
    },
    setPaymentSuccessful(orderId) {
      return setPaymentSuccessful(orderId);
    },
    setOrderAsSuccessful(orderId) {
      if (this.currentOrder) {
        this.currentOrder.id = orderId;
        this.currentOrder.orderDate = moment();
        this.successfulOrder = Object.assign({}, this.currentOrder);
        this.saveSuccessfulOrderToLocalStore(this.successfulOrder);
      }
    },
    clearStore() {
      this.resetOrder();
    },
    saveOrderToLocalStore(order) {
      localStorage.setItem("order", JSON.stringify(order));
    },
    saveSuccessfulOrderToLocalStore(successfulOrder) {
      localStorage.setItem("successfulOrder", JSON.stringify(successfulOrder));
    },
    loadFromLocalStore() {
      const order = localStorage.getItem("order");
      if (order) {
        this.currentOrder = JSON.parse(order);
      }

      const successfulOrder = localStorage.getItem("successfulOrder");
      if (successfulOrder) {
        this.successfulOrder = JSON.parse(successfulOrder);
      }
    },
    resetLocalStore() {
      localStorage.removeItem("order");
    },
    initialize() {
      this.loadFromLocalStore();
    },
  },
});
