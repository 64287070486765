<template>
  <h1>{{ $t("ConfigureDish_Soups_Title") }}</h1>
  <p>{{ $t("ConfigureDish_Soups_Lead") }}</p>
  <div class="row mb-5">
    <div v-for="soup in inventory.soups.soupTypes" :key="soup.id" class="col-6 col-md-4" @click="selectSoup(soup)">
      <div class="item text-center" :class="{ active: currentDish.soup?.type?.name === soup.name }">
        <AnimateContent :delay="0" class="mb-3">
          <img :src="soup.uri" alt="" />
        </AnimateContent>
        <h5>{{ $forCurrentLang(soup.displayName) }}</h5>
      </div>
    </div>
  </div>

  <template v-if="currentDish.soup?.type">
    <h2 id="SizeSelection">{{ $t("ConfigureDish_Soups_Size_Title") }}</h2>
    <p>{{ $t("ConfigureDish_Soups_Size_Lead") }}</p>

    <div class="row mb-5">
      <div v-for="size in inventory.soups.soupSizes" :key="size.id" class="col-6 col-md-4" @click="selectSize(size)">
        <div class="item text-center" :class="{ active: currentDish.soup?.size?.$type === size.$type }">
          <img v-if="size.uri" :src="size.uri" alt="" class="w-25" />
          <h5 class="card-title">{{ $forCurrentLang(size.displayName) }}</h5>
          <div v-if="size.price > 0">+ {{ (size.price / 100).toFixed(2) }} CHF</div>
        </div>
      </div>
    </div>
  </template>

  <div class="text-end fs-3 mb-5">
    <div v-html="$t('ConfigureDish_Price', { price: priceCalculator.calculatePrice(currentDish) })"></div>
  </div>

  <template v-if="currentDish.soup?.type && currentDish.soup?.size">
    <div id="Finish" class="row mb-5">
      <div class="col">
        <b-button variant="primary" size="lg" class="float-end" @click="finish()" v-html="$t('ConfigureDish_Finish_Label')"></b-button>
      </div>
    </div>
  </template>
</template>

<script>
import { toRefs, ref, computed } from "vue";
import { useInventoryStore } from "../../stores/inventory";
import { useOrderStore } from "../../stores/order";
import { PriceCalculatorService } from "../../services/price-calculator-service";

export default {
  props: {
    dishId: {
      type: Number,
      default: null,
    },
  },
  setup(props) {
    // Props
    const { dishId } = toRefs(props);

    // Stores
    const inventoryStore = useInventoryStore();
    const orderStore = useOrderStore();
    const priceCalculator = new PriceCalculatorService();

    // Refs
    const currentDishId = ref(dishId.value);
    const inventory = ref(inventoryStore.availableDishes);
    const order = ref(orderStore.getCurrentOrder);

    const currentDish = computed(() => {
      if (currentDishId.value > 0) {
        return orderStore.getMenu(currentDishId.value);
      } else {
        return {};
      }
    });

    return {
      currentDishId,
      currentDish,

      inventory,
      order,
      orderStore,
      priceCalculator,
    };
  },
  methods: {
    selectSoup(soup) {
      if (this.currentDishId > 0) {
        let dish = this.orderStore.getMenu(this.currentDishId);
        dish.soup = {
          type: soup,
        };

        this.orderStore.updateMenu(this.currentDishId, dish);
      } else {
        this.currentDishId = this.orderStore.addMenu({
          soup: {
            type: soup,
          },
        });

        this.$router.push({
          name: "ConfigureExisting",
          params: { id: this.currentDishId, type: "soup" },
        });
      }
      this.$nextTick(() => {
        document.getElementById("SizeSelection").scrollIntoView({
          behavior: "smooth",
        });
      });
    },
    selectSize(size) {
      let dish = this.orderStore.getMenu(this.currentDishId);
      dish.soup.size = size;

      this.orderStore.updateMenu(this.currentDishId, dish);

      this.$nextTick(() => {
        document.getElementById("Finish").scrollIntoView({
          behavior: "smooth",
        });
      });
    },
    finish() {
      this.$router.push({ name: "Menues" });
    },
  },
};
</script>

<style lang="scss">
.dish-soup {
  position: relative;

  .filter-overlay {
    filter: brightness(0) saturate(100%) invert(71%) sepia(73%) saturate(433%) hue-rotate(40deg) brightness(88%) contrast(95%) opacity(15%);
    display: block;
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    height: 100%;
    z-index: 0;
  }
}
</style>
