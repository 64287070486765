import { computed } from "vue";
import { useI18n } from "vue-i18n";

export function forCurrentLang(data) {
  const { locale } = useI18n();

  if (typeof data === "object") {
    return data[locale.value] || data[0];
  } else {
    return JSON.stringify(data);
  }
}
